<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row
              align-h="between"
              align-v="center"
              class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                Categorías
              </h3>
            </b-col>
            <b-col cols="auto">
              <div class="d-flex align-items-center">
                <b-link
                    :to="{ name: 'createCategory' }"
                >
                  <b-button
                      variant="primary"
                      class="mr-1 text-nowrap"
                  >
                    {{ '+ Añadir nueva categoría' }}
                  </b-button>
                </b-link>
              </div>
            </b-col>
          </b-row>
          <hr class="m-0" />
          <b-row
              align-self="end"
              align-h="end"
              align-v="center"
              no-gutters
              class="px-2 pt-2 pb-1">
            <b-col cols="3">
              <v-select
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectCategories"
                  v-model="parent"
                  :placeholder="'Buscar por categoria'"
              />
            </b-col>
            <b-col cols="4">
              <div class="d-flex justify-content-end align-items-center">
                <b-form-input
                    v-model="searchTerm"
                    :placeholder="$t('Busqueda')"
                    type="text"
                    class="d-inline-block w-auto"
                />
                <b-button class="ml-1" @click="handleSearch" variant="primary">
                  {{ $t("Buscar") }}
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
          >
            <template #cell(parent)="data">
              <span v-if="data.item.parent">{{ data.item.parent.name[currentLanguage] }}</span>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'editCategory', params: { id: data.item.id} }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span
                  class="d-inline-block px-50 text-danger"
                  @click="deleteCategory( data.item.id, data.item.name)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div v-if="totalItems > pageLength" class="d-flex align-items-center justify-content-between mb-1 px-2">
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                {{ 'Por página' }}:
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BPagination, BFormInput, BFormSelect, BButton, BCard, BTable, BCol, BRow, BLink, BFormGroup,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      columns: [
        {
          label: 'Nombre',
          key: 'name',
        },
        {
          label: 'Padre',
          key: 'parent',
        },
        {
          label: 'Orden',
          key: 'order',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      searchTerm: '',
      parent: '',
    }
  },
  computed: {
    ...mapGetters({
      items: 'categories/getItems',
      totalItems: 'categories/getTotalItems',
      selectCategories: 'categories/getSelectCategoriesParent',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    await this.getSelectCategories({ parent: 0 })
    await this.chargeData()
  },
  methods: {
    ...mapActions({
      getSelectCategories: 'categories/getSelectCategories',
      list: 'categories/getListCategories',
      delete: 'categories/delete',
    }),
    chargeData() {
      this.list({
        page: this.currentPage, per_page: this.pageLength, search: this.searchTerm, parent: this.parent.id,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteCategory(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
  watch: {
    parent() {
      this.currentPage = 1
      this.chargeData()
    },
  }
}
</script>
